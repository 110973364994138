/*@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css");*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.warning {
    background-color: #e5c422 !important;
}

.bg-gray{
    background-color: #d1d1d1;
}

.btn-direccion {
    vertical-align: super !important;
}

.vertical_center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

/* Spinner de carga */
.sk-chase {
    width: 40px;
    height: 40px;
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
  }
  
  .sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; 
    animation: sk-chase-dot 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: rgb(185, 167, 0);
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
  .sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }
  
  @keyframes sk-chase {
    100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot {
    80%, 100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4); 
    } 100%, 0% {
      transform: scale(1.0); 
    } 
  }

/* Mapa */
.mapa{
    z-index: 1;
    position: relative;
}


/* #floating-panel {
    position: absolute;
    z-index: 5;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 50%; 
    height: 100%;
} */

/* Navbar Links */
.navbar {
    z-index: 10;
}

.navlink:hover {
    cursor: pointer;
}

.navbar-nav {
    font-size: 0.8rem;
    white-space: nowrap;
}
.nav-fill .nav-item, .nav-fill .nav-link {
    flex: none !important;
}

/* Alertas Reminders */
.alerta {
    border-radius: 0.5rem;
    padding-left: 2px;
    padding-right: 2px;
    color: greenyellow;
}

.icono-reminder {
    background-color: rgb(202, 59, 15);
    padding: 0.5rem;
    color: white;
    border-radius: 1rem;
    margin-right: 5px;
    animation: blinkingIcon 3s infinite alternate;
    animation-timing-function: cubic-bezier(1,0,.56,-0.13);
}

@keyframes blinkingIcon{
    from{
        background-color: rgb(202, 59, 15);
    }

    to{
        background-color: rgb(255, 194, 194); 
        color: #d4d4d4;  
    }
}

.icono-no-reminder {
    background-color: rgb(56, 232, 106);
    padding: 0.5rem;
    color: white;
    border-radius: 1rem;
    margin-right: 5px;
}

.boton-alerta{
    padding: 0.6rem;
    border-radius: 1rem;
    border: none;
    padding: 15px;
}

.boton-alerta i, .boton-alerta p {
    display: inline;
}

/* Dashboard Responsivo */

.height-100{
    height: 100%;
}

.icon-icon {
    padding: 5px;
}

.button-icon {
    background-color: white;
    border: none;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

@media (min-width: 768px) {
    .height-100{
        height: auto;
    }

    .desplegado_grande {
        display: flex;
    }

    .desplegado_chico {
        display: none !important;
    }    
}

@media (max-width: 767px) {
    .desplegado_grande {
        display: none !important;
    }

    .desplegado_chico {
        display: inherit !important;
    }
}


/* Select responsive */

.basic-multi-select {
    min-width: 200px;
}

.react-datepicker-wrapper{
  width: 100%;
}

.react-datepicker-popper{
  z-index: 10 !important;
}

/* .campo-recordatorio label {
    color:  red !important;
}

.campo-recordatorio input, .campo-recordatorio select {
    border-color: red !important;
} */

.campo-recordatorio label{
    animation: blinkinColor 8s infinite;
}

.campo-recordatorio input, .campo-recordatorio select{
    animation: blinkinBorder 8s infinite;
}

@keyframes blinkinColor{
    0%		{ color: #ff0000;}
    100%	{ color: #e23d48;}
}

/* @keyframes blinkinBorder{
    0%		{ border-color: #ff0000;}
    100%	{ border-color: #e23d48;}
} */

.campo-deshabilitado label.label_renegotiation {
    color: #a1a1a1 !important;
}

.campo-deshabilitado input, .campo-deshabilitado select, .campo-deshabilitado .btn_renegociacion {
    color: #a1a1a1 !important;
}

.select-group {
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}

.react-tel-input .form-control {
    width: 100% !important;
}

.msgNotSelected {
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.section_selected {
    background-color: #76fff8;
}

.section_unselected {
    background-color: #dad6d6;
}

.status_ordinary {
    background-color: #dad6d6;
}

.status_inventory {
    background-color: #97ff76;
}

.status_cancelled {
    background-color: #d88071;
}

.value_renegotiation {
    color: #2453a9;
}

.btn_renegociacion {
    border: none;
    background-color: white;
}

.btn_descarga_archivo {
    border: none;
    background-color: transparent;
    padding-left: 10px;
}

.btn_tab_information {
    outline: none !important;
}

.seccion_information {
    border: 1px solid #000;
}

.btn-small {
    font-size: .8rem !important;
}

.fecha-superior {
    color:  orange !important;
}

.fecha-superior input, .fecha-superior select {
    border-color: orange !important;
}

/*Mensajes*/
.soporte_input-message-form {
    align-items: center;
    display: flex;
}

.soporte_input-message-field {
    flex: 1;
    font-size: 1rem;
    margin: 0 1rem;
}

.soporte_input-message-button {
    margin: 0 0.5rem;
}

.soporte_input-message-button .ui.button {
    margin: 0 !important;
}

.disappear_menu {
    display: none;
    transition: all 0.3s ease;
}

.appear_menu {
    transition: all 0.3s ease;
}

/* Progreso de proyecto */
#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: lightgrey;
    padding-inline-start: 0px;
    padding-left: 0px;
    display: flex;
}

.progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    padding-inline-start: 0px;
    padding-left: 0px;
    display: flex;
    padding-left: 0px;
}

#progressbar .actual {
    color: #000000
}

#progressbar .active {
    color: #000000
}

#progressbar .venta {
    list-style-type: none;
    font-size: 12px;
    float: left;
    position: relative;
    text-align: center;
    flex-grow: 1;
}

#progressbar .cursor {
    cursor: pointer;
}

#progressbar .paso_li:before {
    font-family: 'Font Awesome 5 Free';
    content: "\f187";
}

#progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 18px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 25px;
}

#progressbar li.actual:before,
#progressbar li.actual:after {
    background: #d9c328
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: #127427
}

/* PROFILE PICTURE */
.picture-container{
  position: relative;
  cursor: pointer;
  text-align: center;
}
.picture{
  width: 200px;
  height: 200px;
  background-color: #999999;
  border: 4px solid #CCCCCC;
  color: #FFFFFF;
  border-radius: 10%;
  margin: 0px 0px;
  overflow: hidden;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}
.picture:hover{
  border-color: #b79c6b;
}

.picture input[type="file"] {
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0 !important;
  position: absolute;
  top: 0;
  width: 100%;
}

.picture-src{
    width:220px;
    height:220px;
    object-fit:cover;
}


.picture-message{
    max-height: 200px;
    width: -webkit-fill-available;
    border-radius: 2%;
}

.icon{
  margin-top: 15px;
  display: inline;
  padding: 3px 10px;
  border-radius: 50%;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 18px;
  position: absolute;
  bottom: -18px;
  left: 115%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.bg-purple{
  background-color: #b79c6b;
}

.picture-min{
  height: 100px;
  width: 100px;
  border-radius: 10%;
}

@media only screen and (max-width: 500px) {
  .sidebar-lateral {
    display: none !important;
  }

  .tools-button-list-left {
    display: none !important;
  }

  .filter-projects-lg {
    display: none !important;
  }

  .btn-project-information {
    display: none !important;
  }

  .btn-group-buttons {
    display: none !important;
  }

  .btn-project-goback {
    display: none !important;
  }
}

@media only screen and (min-width: 501px) {
  .button-navbar {
    display: none !important;
  }

  .principal-section {
    padding-left: 64px !important;
  }

  .status-button-list {
    display: none !important;
  }

  .tools-button-list {
    display: none !important;
  }

  .btn-group-actions {
    display: none !important;
  }

  .btn-project-goback-s {
    display: none !important;
  }
}

.track_tbl td.track_dot {
    width: 50px;
    position: relative;
    padding: 0;
    text-align: center;
}
.track_tbl td.track_dot:after {
    content: "\f111";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    margin-left: -5px;
    top: 11px;
    color: #d3d3d3;
}

.track_tbl .active.track_dot:after {
    color: #127427 !important;
}

.track_tbl .actual.track_dot:after {
    color: #d9c328 !important;
}

.track_tbl td.track_dot span.track_line {
    background: #d3d3d3;
    width: 3px;
    min-height: 50px;
    position: absolute;
    height: 101%;
}

.track_tbl td.track_dot span.active {
    background: #127427 !important;
}

.track_tbl td.track_dot span.actual {
    background: #d9c328 !important;
}

.track_tbl tbody tr:first-child td.track_dot span.track_line {
    top: 30px;
    min-height: 25px;
}

.track_tbl tbody tr:last-child td.track_dot span.track_line {
    top: 0;
    min-height: 25px;
    height: 10%;
}

.minimo_campo_movil {
    min-width: 200px;
}

/* Campos renegociacion */
.contenedor_renegociacion {
    background-color: #c6faff;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

/* Mensajes de Carga */
.msg-center{
    align-items: center;
    display: flex;
    flex-direction: column; 
    height: calc(100vh - 67px);
    justify-content: center;
    width: 100vw;
}

/* Mensajes de vacio */
.contenido_vacio{
    position: absolute !important;
    top: 50%;
}

/* Checkbox */
.checkdui {
    width: 1.15em;
    height: 1.15em;
}

.checktask {
    width: 1.15em;
    height: 1.15em;
    margin: 0.375rem 0.5rem !important;
}

/* Tablas  */
.table_hover tr:hover {
    background-color: #dbdbdb;
}

.table_hover tr:hover td {
    background-color: transparent;
}

/* Modal de detalles de to do list */
.full_height {
    height: 90%;
}

.full_height .modal-content {
    height: 100%;
}

.full_height .modal-body {
    display: flex;
    flex-flow: column;
    height: 70%;
}

.contenedor_notas {
    overflow-y: scroll;
}

.contenedor_nota_individual {
    height: fit-content;
}

.mensaje_contenedor_notas {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.btn_link_color {
    color: #0c08f1 !important;
}

/* Centrado mensajes */
.proyectos-container {
    min-height: calc(100vh - 67px);
    display: flex;
    flex-direction: column;
}

.proyectos-content-center {
    flex: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Otros */
.campo-recordatorio{
    animation: blinkinColorAlerta 3s infinite;
}

@keyframes blinkinColorAlerta{
    0%		{ color: #ff0000;}
    100%	{ color: #f7a619;}
}

.icono-contenedor_centrado {
    font-size: 3rem;
}

/* Checkbox */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
  
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
  
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: 1px solid rgb(124, 122, 122);
}
  
.container:hover input ~ .checkmark {
    background-color: #ccc;
}
  
.container input:checked ~ .checkmark {
    background-color: #2196F3;
}
  
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
.container input:checked ~ .checkmark:after {
    display: block;
}
  
.container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/*Table To do List */
.tableTasksToDo {
    width: 100%;
    table-layout: auto;
}

.todo-draggable-row{
    display: table-row;
}

.todo-scroll-row{
    width: 4%;
}

.todo-task-row{
    width: 25%
}

.todo-project-row{
    width: 20%;
}

.todo-asignedto-row{
    width: 13%;
}

.todo-deadline-row{
    color: #000;
    width: 10%;
}

.todo-priority-row{
    width: 8%;
    text-align: right;
}

.todo-status-row{
    width: 9%;
    text-align: right;
}

.todo-options-row{
    width: 11%;
}

.todo-deadline-warning{
    animation: blinkingDeadline 3s infinite alternate;
    animation-timing-function: cubic-bezier(1,0,.56,-0.13);
}

@keyframes blinkingDeadline{
    from{
        color: rgb(0, 0, 0);
    }

    to{
        color: rgb(255, 0, 0);
    }
}

/* Badges */
.badge_new_task{
    background-color: #fff939;
    color: #000;
}

.badge_in_progress{
    background-color: #caff4b;
    color: #000;
}

/* Lightbox */
.ReactModal__Overlay {
    z-index: 1051 !important;
}

/* Time picker */
.react-time-picker__wrapper {
    border: transparent !important;
}

/* Tamaño de campos */
.contenedor_garages {
    display: flex;
    justify-content: space-between;
}

.campo_fecha{
    max-width: 300px !important;
    width: 300px !important;
}

.campo_numero_sm{
    max-width: 100px !important;
    width: 100px !important;
}

.campo_numero_lg{
    max-width: 200px !important;
    width: 200px !important;
}

.campo_dinero{
    max-width: 250px !important;
    width: 250px !important;
}

.campo_radio_sm{
    max-width: 200px !important;
    width: 200px !important;
}

.campo_radio_lg{
    max-width: 300px !important;
    width: 300px !important;
}

.campo_select_sm{
    max-width: 200px !important;
    width: 200px !important;
}

.campo_select_lg{
    max-width: 300px !important;
    width: 300px !important;
}

.campo_select_xl{
    max-width: 400px !important;
    width: 400px !important;
}